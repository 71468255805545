import React, { Component } from 'react'
import { graphql } from 'gatsby'
import {get,first} from 'lodash'
import Layout from '../components/layout'
import { getDataForLanguage } from '../utils/getDataForLanguage'
import Paragraphs from '../components/helpers/paragraphs';

export class AboutUsPage extends Component {
    render() {
        const allPageData = get(this, "props.data.allContentfulAboutUsPage.edges", '');
        let pageData = first(getDataForLanguage(allPageData.map(item => item.node), process.env.COUNTRY));

        return (
            <Layout 
                title={get(pageData, 'metaTitle','')} 
                description={get(pageData, 'metaDescription','')}
                pathName="/"
            >
                <div className="basic-page">
                    <div className="container" id="maincontent">
                        <h1 className="page-title text-center">{get(pageData, 'title', '')}</h1>
                        <div className="basic-page__text">
                            <Paragraphs value={get(pageData,'description','')} />
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default AboutUsPage

export const pageQuery = graphql`
  query AboutUsPageQuery {
    allContentfulAboutUsPage {
        edges {
          node {
            metaTitle
            metaDescription
            title
            description {
              description
            }
            node_locale
            contentful_id
          }
        }
    }
  }
`
